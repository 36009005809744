import { createContext } from "react";
import "socket.io-client";

interface AppContextInterface {
  socket: SocketIOClient.Socket | null;
}

const context: AppContextInterface = {
  socket: null,
};

const AppContext = createContext(context);

export default AppContext;
