import { useContext } from "react";
import "socket.io-client";
import AppContext from "../context/app";

const useSocket = (): SocketIOClient.Socket | null => {
  const { socket } = useContext(AppContext);
  return socket;
};

export default useSocket;
