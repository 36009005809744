import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { useLocation } from "react-router-dom";

import {
  Game,
  User,
  JOIN_GAME_EVENT,
  GAME_FAILED_EVENT,
  JOIN_GAME_SUCCESS,
  SetUsernamePayload,
  SET_USERNAME_EVENT,
  UPDATE_GAME_EVENT,
  START_TIMER_EVENT,
  NEW_WORD_EVENT,
  NewWordPayload,
  INCREMENT_SCORE_EVENT,
  IncrementScorePayload,
  DECREMENT_SCORE_EVENT,
} from "../../utils/events";
import useSocket from "../../hooks/socket";

const MIN_USERNAME_LENGTH = 3;

const GamePage: React.FunctionComponent = () => {
  const socket = useSocket();
  const { pathname } = useLocation();
  const gameId = pathname.replace("/game/", "");

  // States

  const [game, setGame] = useState<Game | null>(null);
  const [hasError, setHasError] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameHasBeenSet, setUsernameHasBeenSet] = useState(false);
  const [word, setWord] = useState<string | null>(null);

  // Effects

  useEffect(() => {
    if (!socket) return;

    socket.on(JOIN_GAME_SUCCESS, onJoinGameSuccess);
    socket.on(GAME_FAILED_EVENT, onGameFailed);
    socket.on(UPDATE_GAME_EVENT, onUpdateGame);
    socket.on(NEW_WORD_EVENT, onNewWord);

    socket.emit(JOIN_GAME_EVENT, { gameId });
  }, [gameId, socket]);

  // Handlers

  const onJoinGameSuccess = (game: Game) => {
    setGame(game);
  };

  const onGameFailed = () => {
    setHasError(true);
  };

  const onUpdateGame = (game: Game) => {
    setGame({ ...game });
  };

  const onNewWord = ({ value }: NewWordPayload) => {
    const randomDelay = Math.random() * 3000 + 1000;
    setTimeout(() => {
      setWord(value);
    }, randomDelay);
  };

  const setUsename = () => {
    if (!socket || username.length < MIN_USERNAME_LENGTH) return;
    const payload: SetUsernamePayload = { username };
    socket.emit(SET_USERNAME_EVENT, payload);
    setUsernameHasBeenSet(true);
  };

  const startTimer = () => {
    if (!socket) return;
    socket.emit(START_TIMER_EVENT);
  };

  const incrementScore = () => {
    if (!socket) return;
    const payload: IncrementScorePayload = { value: word ?? "" };
    socket.emit(INCREMENT_SCORE_EVENT, payload);
    setWord(null);
  };

  const decrementScore = () => {
    if (!socket) return;
    socket.emit(DECREMENT_SCORE_EVENT);
    setWord(null);
  };

  // Rendering

  const connectionMessage = (
    <div className="container">
      <h1>Connexion à la partie en cours</h1>
    </div>
  );

  const failedConnectionMessage = (
    <div className="container">
      <h1 style={{ color: "tomato" }}>
        Erreur de connexion à la partie en cours
      </h1>
    </div>
  );

  const usernameInput = (
    <div className="container">
      <div className="form">
        <label>
          {
            "C'est quoi ton ptit nom beau (ou ma belle mais j'aime mieux les gars perso)?"
          }
        </label>
        <input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setUsename();
            }
          }}
        />
        <button
          disabled={username.length < MIN_USERNAME_LENGTH}
          onClick={setUsename}
        >
          Choisir
        </button>
      </div>
    </div>
  );

  if (hasError) return failedConnectionMessage;
  if (!game) return connectionMessage;
  if (!usernameHasBeenSet) return usernameInput;

  const {
    title,
    teamAUsers,
    teamBUsers,
    currentAUserId,
    currentBUserId,
    currentTeam,
    scoreA,
    scoreB,
    timer,
    status,
    round,
  } = game;

  const currentUserSocketId = (): string => {
    return currentTeam === "B" ? currentBUserId : currentAUserId;
  };

  const currentUser = (): User | undefined => {
    if (currentTeam === "B") {
      const index = teamBUsers.findIndex(
        ({ socketId }) => currentBUserId === socketId
      );
      if (index > -1) return teamBUsers[index];
    } else {
      const index = teamAUsers.findIndex(
        ({ socketId }) => currentAUserId === socketId
      );
      if (index > -1) return teamAUsers[index];
    }
    return undefined;
  };

  const canStart =
    teamAUsers.length > 0 && teamBUsers.length > 0 && status === "PAUSE";
  const started = status === "PLAY";

  const someoneElseTurn = (
    <p>
      {"C'est au tour de "}
      <span>{currentUser()?.username ?? ""}</span>
      {` pour ${timer}s`}
    </p>
  );

  const wordRender = word ? (
    word
  ) : (
    <Loader type="Puff" color="#282C34" height={30} width={30} />
  );

  const yourTurn = (
    <div>
      <p>{`C'est à ton tour pour ${timer}s`}</p>
      {canStart && <button onClick={startTimer}>Commencer</button>}
      {started && (
        <div>
          <p className="flex-center">
            {"Ton mot: "}
            {wordRender}
          </p>
          <div className="button-holder">
            <button disabled={!word} onClick={incrementScore}>
              {"Je l'ai eu"}
            </button>{" "}
            <button
              disabled={!word}
              onClick={decrementScore}
              className="secondary"
            >
              {"Passer"}
            </button>
          </div>
        </div>
      )}
    </div>
  );

  const userView =
    currentUserSocketId() === socket?.id ? yourTurn : someoneElseTurn;

  const gameOver = round > 3;

  let winnerString = "OMG! Égalité!!! Vous êtes tous des champions";
  if (scoreA > scoreB)
    winnerString =
      "L'équipe A a gagné! Ahah! L'équipe B c'est des gros bébés perdant.";
  else if (scoreA < scoreB)
    winnerString =
      "Les champions c'est l'équipe B! L'équipe A sont gênants pis sûrement laids.";

  const subheader = gameOver ? winnerString : `Round ${round}`;

  return (
    <div className="container">
      <h1>{title}</h1>
      <h4>{subheader}</h4>

      {/* Leaderboard */}
      <div className="leaderboard">
        <div>
          <h4>Équipe A</h4>
          <ul>
            {teamAUsers.map(({ username, socketId }, index) => (
              <li
                key={index}
                style={
                  currentAUserId === socketId && currentTeam === "A"
                    ? { color: "var(--main-color)" }
                    : {}
                }
              >
                {username}
              </li>
            ))}
          </ul>
          <hr />
          <p>Score: {scoreA}</p>
        </div>

        <div>
          <h4>Équipe B</h4>
          <ul>
            {teamBUsers.map(({ username, socketId }, index) => (
              <li
                key={index}
                style={
                  currentBUserId === socketId && currentTeam === "B"
                    ? { color: "var(--main-color)" }
                    : {}
                }
              >
                {username}
              </li>
            ))}
          </ul>
          <hr />
          <p>Score: {scoreB}</p>
        </div>
      </div>

      {/* User view */}
      {!gameOver && userView}
    </div>
  );
};

export default GamePage;
