import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Game,
  CREATE_GAME_EVENT,
  GAME_CREATED_EVENT,
  CreateGamePayload,
} from "../../utils/events";
import useSocket from "../../hooks/socket";

const HomePage: React.FunctionComponent = () => {
  const { push } = useHistory();
  const socket = useSocket();

  // States

  const [title, setTitle] = useState("");
  const [wordsString, setWordsString] = useState("");

  // Effects

  useEffect(() => {
    if (!socket) return;

    socket.on(GAME_CREATED_EVENT, onGameCreated);
  }, [socket]);

  // Handlers
  const canCreate = title.length > 0 && wordsString.length > 0;

  const createGame = () => {
    if (!socket || !canCreate) console.log("No socket error"); // TODO: Handle better

    const payload: CreateGamePayload = { wordsString, title };
    socket?.emit(CREATE_GAME_EVENT, payload);
  };

  const onGameCreated = (game: Game) => {
    push(`/game/${game.id}`);
  };

  // Rendering

  return (
    <div className="container">
      <h1>Créer une partie</h1>
      <div className="form">
        <label>Entrer le nom de la partie</label>
        <input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <label>Entrer les mots de la partie</label>
        <textarea
          value={wordsString}
          onChange={(e) => {
            setWordsString(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              createGame();
            }
          }}
        />
        <button disabled={!canCreate} onClick={createGame}>
          Create
        </button>
      </div>
    </div>
  );
};

export default HomePage;
