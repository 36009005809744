import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import socketIOClient from "socket.io-client";
import AppContext from "./context/app";

import { Header } from "./components";

import Home from "pages/home";
import Game from "pages/game";

const API_URL = "https://fibrose.api.thirdbridge.ca/";

const App: React.FunctionComponent = () => {
  // States
  const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null);

  // Handlers

  useEffect(() => {
    if (socket !== null) return;

    const s = socketIOClient(API_URL, {
      query: `action=${"create"}`,
    });
    s.on("connect_error", function () {
      console.log("Connection Failed");
    });
    s.on("connect", function () {
      setSocket(s);
      console.log("Connected");
    });
    s.on("disconnect", function () {
      console.log("Disconnected");
    });
  }, [socket, setSocket]);

  // Rendering

  return (
    <AppContext.Provider value={{ socket }}>
      <Header />
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/game/:id">
            <Game />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </AppContext.Provider>
  );
};

export default App;
