// Models interfaces

export type Team = "A" | "B";

export interface Word {
  value: string;
  used: boolean;
}

export type GameStatus = "PLAY" | "PAUSE";

export interface Game {
  id: string;
  title: string;
  words: Word[];
  teamAUsers: User[];
  teamBUsers: User[];
  scoreA: number;
  scoreB: number;
  currentAUserId: string;
  currentBUserId: string;
  currentTeam: Team;
  timer: number;
  status: GameStatus;
  round: number;
}

export interface User {
  socketId: string;
  username: string;
}

// Game creation

export const CREATE_GAME_EVENT = "CREATE_GAME_EVENT";
export interface CreateGamePayload {
  wordsString: string;
  title: string;
}

export const GAME_CREATED_EVENT = "GAME_CREATED_EVENT ";

// Game join

export const JOIN_GAME_EVENT = "JOIN_GAME_EVENT";
export interface JoinGamePayload {
  gameId: string;
}
export const JOIN_GAME_SUCCESS = "JOIN_GAME_SUCCESS";

// Set username

export const SET_USERNAME_EVENT = "SET_USERNAME_EVENT";
export interface SetUsernamePayload {
  username: string;
}

// Update game

export const UPDATE_GAME_EVENT = "UPDATE_GAME_EVENT";
export const GAME_FAILED_EVENT = "GAME_FAILED_EVENT";

// In game

export const TIMER_DURATION_S = 10;
export const START_TIMER_EVENT = "START_TIMER_EVENT";
export const NEW_WORD_EVENT = "NEW_WORD_EVENT";
export interface NewWordPayload {
  value: string;
}

export const INCREMENT_SCORE_EVENT = "INCREMENT_SCORE_EVENT";
export interface IncrementScorePayload {
  value: string;
}

export const DECREMENT_SCORE_EVENT = "DECREMENT_SCORE_EVENT";
