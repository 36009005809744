import React from "react";

import logoSrc from "../../assets/images/logo.svg";

const Header: React.FunctionComponent = () => {
  return (
    <header>
      <div>
        <img className="logo" src={logoSrc} />
        <p>
          La boulette <br /> <b>par BigDog</b>
        </p>
      </div>
    </header>
  );
};

export default Header;
